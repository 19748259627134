$blue : #014085;


@media (max-width : 768px) {
  
  .Primushestva{
    padding-bottom: 40px;
    h2{
      color: #2D3282;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
    }
    
  
    .card{
      border-radius: 16px;
      background: #F9FAFB;
      border: none;
      height: 100%;
      
      .card-icon{
        width: 48px;
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        background: linear-gradient(90deg, rgba(25, 113, 208, 0.10) 0%, rgba(27, 123, 228, 0.10) 100%);
        margin-bottom: 20px;
      }
  
      h3{
        color: var(--Gray-900, #101828);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 155.556% */
      }
  
      p{
        color: var(--Gray-600, #475467);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
      }
    }
  }
  
  .Home{

    .HomeHeader{
      background-image: url('./Images/bgbg.png');
      height: 687px;
      background: $blue;
      width: 100%;
      
      .container{
        position: relative;
        
      }

      .mySwiper{
        display: block;
      }

      
      .row{
        align-items: center;
        flex-direction: column-reverse;
        .header-img{
          width: 100%;
          margin-bottom: 32px;
        }
  
        
    
        .dn{
          display: none;
          position: absolute;
          bottom: -99px;
          padding: 32px;
          background: #FFFFFF;
          border-radius: 16px;
          box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 1px 1px 16px 0px rgba(0, 0, 0, 0.02);
          
          
          
        }
    
        
    
        h2{
          font-size: 24px;
          margin-bottom: 16px;
          span{
            color: #EECB6D;
          }
        }
    
        h3{
          font-size: 14px;
          line-height: 20px; /* 150% */
          margin-bottom: 84px;
          br{
            display: none;
          }
        }
      }
    }
  
    .HomeCatalog{
      padding-top: 80px;
      margin-bottom: 120px;
      h2{
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 16px;
      }
  
      .card{
        border-radius: 20px;
        background: var(--Primary-color-White, #FFF);
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 1px 1px 16px 0px rgba(0, 0, 0, 0.02);
        border: none;
        margin-bottom: 16px;
        img{
          width: 100%;
          object-fit: cover;
          // margin-bottom: 16px;
        }

        .card-body{
          padding: 8px; 
        }
  
        h3{
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }
  
        button{
          width: 100%;
          border-radius: 8px;
          border: none;
          padding: 6px 0;
          background: var(--Brand, linear-gradient(90deg, #1971D0 0%, #1B7BE4 100%));
          box-shadow: 0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02);
          color: var(--Light-Primary-Contrast, #FFF);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 171.429% */
          letter-spacing: 0.4px;
          text-transform: uppercase;
        }
      }
    }
  
    
  
    .SectionForm{
      background: #F9FAFB;
      padding: 24px 0;
      margin-bottom: 40px;
  
      form{
        h2{
          color: #2D3282;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 155.556% */
          margin-bottom: 16px;
        }
  
        h3{
          color: var(--Gray-600, #475467);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          margin-bottom: 16px;
        }
  
        .form-inputs{
          display: flex;
          justify-content: center;
          gap: 8px;
          flex-wrap: wrap;
  
          input{
            padding: 8px 14px 8px 16px;
            color: var(--Gray-500, #667085);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
  
          button{
            display: flex;
            padding: 8px 14px;
            justify-content: center;
            align-items: center;
            color: var(--White, #FFF);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
            gap: 8px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1px solid var(--Brand, #1971D0);
            background: var(--Brand, linear-gradient(90deg, #1971D0 0%, #1B7BE4 100%));
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }
      }
    }
  
    .FAQ{
      margin-bottom: 120px;
      h2{
        color: #2D3282;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 155.556% */
        margin-bottom: 16px;
      }
  
      .accardion{
        padding: 0;
        border: none;
        .accardion-icon{
          color: #1971D0;
          transform: scale(1);
        }
  
        .accardion-tittle{
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }
  
        .accardion-txt{
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }

  .About{
    padding: 64px 0;
    .AboutBody{
      background: var(--Gray-50, #F9FAFB);
      margin-bottom: 24px;
      .row{
        align-items: center;
  
        img{
          width: 100% !important;
        }
        h2{
          font-size: 18px;
          font-style: normal;
          margin: 0;
        }
  
        h3{
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
  
        .purecounter, span{
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
  
        p{
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 155.556% */
          background: var(--gradient-gray-80060090-deg, linear-gradient(63deg, #1D2939 16.72%, #475467 83.39%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  .Contacts{
    padding: 40px 0 40px 0;
    .ContactBody{
  
      h2{
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 166.667% */
      }
  
      .row{
        align-items: center;
      }
      .contact-socials{
        display: flex;
        flex-direction: column;
        gap: 16px;
  
  
      }
  
      .contact-location{
        div{
          width: 100%;
  
          iframe{
            width: 100%;
            border-radius: 25px;
          }
        }
      }
  
      .contact-links{
        display: flex;
        gap: 16px;
        .social-icon{
          width: 48px;
          height: 48px;
          margin-bottom: 32px;
          padding: 12px;
          border-radius: 28px;
          background: linear-gradient(90deg, rgba(25, 113, 208, 0.10) 0%, rgba(27, 123, 228, 0.10) 100%);
        }
  
        .social-txt{
          p{
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            padding: 0;
            margin: 0;
            
          }
  
          a{
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            
            line-height: 24px; /* 150% */
            background: var(--Brand, linear-gradient(90deg, #1971D0 0%, #1B7BE4 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
  
    }
  }
  

  .Footer{
    background: #F9FAFB;
    .footer-body{
      padding: 32px 0;
      text-align: center;
      border-bottom: 1px solid #E4E7EC;
  
      img{
        margin-bottom: 32px;
      }
  
      ul{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        padding: 0;
        margin: 0;
  
        li{
          padding: 0;
          margin: 0;
          a{
            color: var(--Gray-600, #475467);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
          }
        }
      }
    }
  
    .footer-bottom{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 32px 0 48px 0;
  
      .brand{
        font-weight: bold;
      }
      p{
        color: var(--Gray-500, #667085);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
  
        a{
          font-weight: bold;
        }
      }
    }
  }

  .btn-call{
    opacity: 1;
  }
}

@media (max-width : 992px) {
  .Navbar{
    z-index: 99;
    padding: 16px 0;
    background: $blue;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    position: fixed;
    .nav-body{
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      ul{
        display: none;
        gap: 32px;
        align-items: center;
        margin: 0;
        padding: 0;
        a{
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }


      

      .burger{
        display: block;
      }

      .brand-number{
        display: none;
      }
  
      .nav-number{
        display: flex;
        align-items: center;
        gap: 8px;
        
       
        p{
          color: #FFF;
          font-size: 18px;
          margin: 0;
          padding: 0;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 155.556% */
        }
      }
    }
  }

  .Home{
    padding-top: 72px;
  }

  .About, .Contacts, .Products{
    padding-top: 95px;
  }
}