@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

$blue : #014085;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

#root{
  overflow-x: hidden;
}

ul, li, p, h2, h3{
  list-style-type: none;
  margin: 0 ;
  padding: 0 ;
}

a{
  text-decoration: none;
}

.container{
  max-width: 1440px;
}

.active{
  color: #EECB6D !important;
}

.App{
  position: relative;

  
  .scrol-top{
    opacity: 0;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .fixed{
    opacity: 1;
    transition: 1s;
  }
}

.Navbar{
  padding: 16px 0;
  background: $blue;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .nav-body{
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul{
      display: flex;
      gap: 32px;
      align-items: center;
      margin: 0;
      padding: 0;
      a{
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
    }

    .burger{
      display: none;
    }

  

    

    .nav-number{
      display: flex;
      align-items: center;
      gap: 8px;
      
     
      p{
        color: #FFF;
        font-size: 18px;
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
      }
    }
  }
}

.NavModal{
  .modal-body{
    padding: 25px 0 45px 0;

  }

  

  .modal-nav{

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center !important;
    gap: 20px;
    margin: 0; padding: 0;

    li{
      display: inline-block;
      margin: 0;
      padding: 0;

      .active{
        color: #EECB6D !important;
      }

      a{
        color: $blue !important;
        font-weight: bold;


      }
    }
  }
}

.order{
  .row{
    align-items: center;

    .matras-name{
      font-size: 24px;
      color: var(--Gray-600, #475467);
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
    }

    h4{
      color: var(--Gray-600, #475467);
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
    }

    p{
      color: var(--Gray-500, #667085);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    #box-size{
      border-radius: 8px;
      padding: 8px 14px;
      cursor: pointer;
      display: inline-block;
      margin: 8px;
      background: linear-gradient(90deg, rgba(25, 113, 208, 0.10) 0%, rgba(27, 123, 228, 0.10) 100%) ;
      color: #1971D0;
      h5{
        margin: 0;
        padding: 0;
      }
    }
    .active{
      background: var(--Brand, linear-gradient(90deg, #1971D0 0%, #1B7BE4 100%)) !important;
      
    }

    .price{
      margin-top: 30px;
      margin-bottom: 30px;
      span{
        color: $blue;
        font-weight: bold;
      }
    }
  }
}

.Primushestva{
  padding-bottom: 120px;
  h2{
    color: #2D3282;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
    text-transform: uppercase;
    margin-bottom: 32px;
  }
  

  .card{
    border-radius: 16px;
    background: #F9FAFB;
    border: none;
    height: 100%;
    transition: 0.3s ease-in-out;


    &:hover{
      transition: 0.3s ease-in-out;
      transform: scale(1,1);
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }
    
    .card-icon{
      width: 48px;
      display: flex;
      padding: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      background: linear-gradient(90deg, rgba(25, 113, 208, 0.10) 0%, rgba(27, 123, 228, 0.10) 100%);
      margin-bottom: 20px;
    }

    h3{
      color: var(--Gray-900, #101828);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 150% */
      margin-bottom: 8px;
    }

    p{
      color: var(--Gray-600, #475467);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
}

.Home{
  .HomeHeader{
    background-image: url('./Images/bgbg.png');
    height: 800px;
    background: $blue;
    width: 100%;
    
    .container{
      position: relative;
      height: 800px;
    }

    .mySwiper{
      display: none;
      padding: 12px;
      border-radius: 16px;
      background: var(--White, #FFF);
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 1px 1px 16px 0px rgba(0, 0, 0, 0.02);
      
      .swiper-button-prev, .swiper-button-next{
        display: none !important;
      }

      .card{
        border: none;   
        box-shadow: none;     
        .d-flex{
          gap: 20px;
        }

        img{
          width: 48px;
          height: 48px;
        }

        .card-info{
          h3{
            color: var(--Gray-900, #101828);
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 155.556% */
          }

          p{
            color: var(--Gray-600, #475467);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
          }
        }
      }
    }

    .row{
      align-items: center;
      .header-img{
        width: 100%;
      }

      

      
  
      .header-icons{
        position: absolute;
        bottom: -99px;
        padding: 32px;
        background: #FFFFFF;
        border-radius: 16px;
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 1px 1px 16px 0px rgba(0, 0, 0, 0.02);
        
        
        .card{
          height: 100% !important;
          padding: 12px;
          border-radius: 16px;
          background: #F9FAFB;
          border: none;
          .d-flex{
            gap: 20px;
            .card-icon{
              width: 48px;
              height: 48px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 12px;
              border-radius: 24px;
              background: linear-gradient(90deg, rgba(25, 113, 208, 0.10) 0%, rgba(27, 123, 228, 0.10) 100%);
              
              svg{
                width: 24px;
                height: 24px;
              }
            }
  
            .card-info{
              h3{
                color: var(--Gray-900, #101828);
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px; /* 150% */
                margin-bottom: 8px;
              }
  
              p{
                color: var(--Gray-600, #475467);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
              }
            }
          }
        }
      }
  
      
  
      h2{
        color: #FFF;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 16px;
        span{
          color: #EECB6D;
        }
      }
  
      h3{
        color: rgba(255, 255, 255, 0.75);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 150% */
      }
    }
  }

  .HomeCatalog{
    padding-top: 200px;
    margin-bottom: 120px;
    h2{
      color: #2D3282;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
      text-transform: uppercase;
      margin-bottom: 32px;
    }

    .card{
      border-radius: 20px;
      background: var(--Primary-color-White, #FFF);
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 1px 1px 16px 0px rgba(0, 0, 0, 0.02);
      border: none;
      img{
        width: 100%;
        object-fit: cover;
        // margin-bottom: 16px;
      }

      h3{
        font-size: 24px;
        font-style: normal;
        margin-bottom: 16px;
        font-weight: 600;
        line-height: 32px; /* 133.333% */
        background: var(--gradient-gray-80060090-deg, linear-gradient(63deg, #1D2939 16.72%, #475467 83.39%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      button{
        width: 100%;
        border-radius: 8px;
        border: none;
        padding: 8px 0;
        background: var(--Brand, linear-gradient(90deg, #1971D0 0%, #1B7BE4 100%));
        box-shadow: 0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02);
        color: var(--Light-Primary-Contrast, #FFF);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 173.333% */
        letter-spacing: 0.46px;
        text-transform: uppercase;
      }
    }
  }

  

  .SectionForm{
    background: #F9FAFB;
    padding: 64px 0;
    margin-bottom: 120px;

    form{
      h2{
        color: #2D3282;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px; /* 122.222% */
        letter-spacing: -0.72px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
      }

      h3{
        color: var(--Gray-600, #475467);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 150% */
        margin-bottom: 40px;
      }

      .form-inputs{
        display: flex;
        justify-content: center;
        gap: 16px;
        flex-wrap: wrap;

        input{
          width: 360px;
          padding: 12px 14px 12px 16px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid var(--Gray-300, #D0D5DD);
          background: var(--White, #FFF);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: var(--Gray-500, #667085);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }

        button{
          max-width: 360px;
          display: flex;
          padding: 12px 20px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;
          border-radius: 8px;
          border: 1px solid var(--Brand, #1971D0);
          background: var(--Brand, linear-gradient(90deg, #1971D0 0%, #1B7BE4 100%));
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: var(--White, #FFF);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }
    }
  }

  .FAQ{
    margin-bottom: 120px;
    h2{
      color: #2D3282;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
      margin-bottom: 64px;
    }

    .accardion{
      padding: 0 32px;
      border: none;
      .accardion-icon{
        color: #1971D0;
        transform: scale(1.5);
      }

      .accardion-tittle{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 133.333% */
      }

      .accardion-txt{
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
    }
  }
}

.About{
  padding: 64px 0;
  .AboutBody{
    background: var(--Gray-50, #F9FAFB);
    margin-bottom: 72px;
    .row{
      align-items: center;

      img{
        width: 100%;
      }
      h2{
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 125% */
        letter-spacing: -0.96px;
        margin-bottom: 24px;
      }

      h3{
        color: var(--Gray-600, #475467);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 150% */
      }

      .purecounter, span{
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px; /* 122.222% */
        letter-spacing: -0.72px;
        background: var(--Brand, linear-gradient(90deg, #1971D0 0%, #1B7BE4 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      p{
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        background: var(--gradient-gray-80060090-deg, linear-gradient(63deg, #1D2939 16.72%, #475467 83.39%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.Contacts{
  padding: 80px 0 150px 0;
  .ContactBody{

    h2{
      color: var(--Gray-900, #101828);
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
      text-transform: uppercase;
      margin-bottom: 32px;
    }

    .row{
      align-items: center;
    }
    .contact-socials{
      display: flex;
      flex-direction: column;
      gap: 32px;


    }

    .contact-location{
      div{
        width: 100%;

        iframe{
          width: 100%;
          border-radius: 25px;
        }
      }
    }

    .contact-links{
      display: flex;
      gap: 16px;
      .social-icon{
        width: 48px;
        height: 48px;
        margin-bottom: 32px;
        padding: 12px;
        border-radius: 28px;
        background: linear-gradient(90deg, rgba(25, 113, 208, 0.10) 0%, rgba(27, 123, 228, 0.10) 100%);
      }

      .social-txt{
        p{
          color: var(--Gray-900, #101828);
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px; /* 150% */
        }

        a{
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          background: var(--Brand, linear-gradient(90deg, #1971D0 0%, #1B7BE4 100%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

  }
}

.Footer{
  background: #F9FAFB;
  .footer-body{
    padding: 64px 0;
    text-align: center;
    border-bottom: 1px solid #E4E7EC;

    img{
      margin-bottom: 32px;
    }

    ul{
      display: flex;
      justify-content: center;
      gap: 32px;

      li{
        a{
          color: var(--Gray-600, #475467);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }
    }
  }

  .footer-bottom{
    display: flex;
    justify-content: space-between;
    padding: 32px 0 48px 0;

    .brand{
      font-weight: bold;
    }
    p{
      color: var(--Gray-500, #667085);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */

      a{
        font-weight: bold;
      }
    }
  }
}

.btn-call {
  opacity: 0;
  background: #38a3fd;
  border: 2px solid #38a3fd;
  border-radius: 50%;
  box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3);
  cursor: pointer;
  height: 60px;
  width: 60px;
  text-align: center;
  position: fixed;
  right: 15px;
  bottom: 80px;
  z-index: 999;
  transition: .3s;
  -webkit-animation: hoverWave linear 1s infinite;
  animation: hoverWave linear 1s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.btn-call__ico {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 1200ms ease 0s normal none 1 running shake;
  animation-iteration-count: infinite;
  -webkit-animation: 1200ms ease 0s normal none 1 running shake;
  -webkit-animation-iteration-count: infinite;
  color: white;
  font-size: 30px;
  padding-top: 5px;
  transition: .3s all;
}

.btn-call:hover {
  background-color: #fff;
}

.btn-call:hover .btn-call__ico {
  color: #38a3fd;
}

@-webkit-keyframes hoverWave {
  0% {
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
  }

  40% {
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
  }

  80% {
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 26.7px rgba(56, 163, 253, 0.067)
  }

  100% {
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0.0)
  }
}

@keyframes hoverWave {
  0% {
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
  }

  40% {
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
  }

  80% {
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 26.7px rgba(56, 163, 253, 0.067)
  }

  100% {
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0.0)
  }
}

@keyframes shake {
  0% {
      transform: rotateZ(0deg);
      -ms-transform: rotateZ(0deg);
      -webkit-transform: rotateZ(0deg);
  }

  10% {
      transform: rotateZ(-30deg);
      -ms-transform: rotateZ(-30deg);
      -webkit-transform: rotateZ(-30deg);
  }

  20% {
      transform: rotateZ(15deg);
      -ms-transform: rotateZ(15deg);
      -webkit-transform: rotateZ(15deg);
  }

  30% {
      transform: rotateZ(-10deg);
      -ms-transform: rotateZ(-10deg);
      -webkit-transform: rotateZ(-10deg);
  }

  40% {
      transform: rotateZ(7.5deg);
      -ms-transform: rotateZ(7.5deg);
      -webkit-transform: rotateZ(7.5deg);
  }

  50% {
      transform: rotateZ(-6deg);
      -ms-transform: rotateZ(-6deg);
      -webkit-transform: rotateZ(-6deg);
  }

  60% {
      transform: rotateZ(5deg);
      -ms-transform: rotateZ(5deg);
      -webkit-transform: rotateZ(5deg);
  }

  70% {
      transform: rotateZ(-4.28571deg);
      -ms-transform: rotateZ(-4.28571deg);
      -webkit-transform: rotateZ(-4.28571deg);
  }

  80% {
      transform: rotateZ(3.75deg);
      -ms-transform: rotateZ(3.75deg);
      -webkit-transform: rotateZ(3.75deg);
  }

  90% {
      transform: rotateZ(-3.33333deg);
      -ms-transform: rotateZ(-3.33333deg);
      -webkit-transform: rotateZ(-3.33333deg);
  }

  100% {
      transform: rotateZ(0deg);
      -ms-transform: rotateZ(0deg);
      -webkit-transform: rotateZ(0deg);
  }
}

@-webkit-keyframes shake {
  0% {
      transform: rotateZ(0deg);
      -ms-transform: rotateZ(0deg);
      -webkit-transform: rotateZ(0deg);
  }

  10% {
      transform: rotateZ(-30deg);
      -ms-transform: rotateZ(-30deg);
      -webkit-transform: rotateZ(-30deg);
  }

  20% {
      transform: rotateZ(15deg);
      -ms-transform: rotateZ(15deg);
      -webkit-transform: rotateZ(15deg);
  }

  30% {
      transform: rotateZ(-10deg);
      -ms-transform: rotateZ(-10deg);
      -webkit-transform: rotateZ(-10deg);
  }

  40% {
      transform: rotateZ(7.5deg);
      -ms-transform: rotateZ(7.5deg);
      -webkit-transform: rotateZ(7.5deg);
  }

  50% {
      transform: rotateZ(-6deg);
      -ms-transform: rotateZ(-6deg);
      -webkit-transform: rotateZ(-6deg);
  }

  60% {
      transform: rotateZ(5deg);
      -ms-transform: rotateZ(5deg);
      -webkit-transform: rotateZ(5deg);
  }

  70% {
      transform: rotateZ(-4.28571deg);
      -ms-transform: rotateZ(-4.28571deg);
      -webkit-transform: rotateZ(-4.28571deg);
  }

  80% {
      transform: rotateZ(3.75deg);
      -ms-transform: rotateZ(3.75deg);
      -webkit-transform: rotateZ(3.75deg);
  }

  90% {
      transform: rotateZ(-3.33333deg);
      -ms-transform: rotateZ(-3.33333deg);
      -webkit-transform: rotateZ(-3.33333deg);
  }

  100% {
      transform: rotateZ(0deg);
      -ms-transform: rotateZ(0deg);
      -webkit-transform: rotateZ(0deg);
  }
}